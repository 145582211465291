
<template>
  <apexchart
    type="pie"
    height="350"
    :options="chartOptions"
    :series="series"
  />
</template>
<script>
  import Vue from 'vue'
  import VueApexCharts from 'vue-apexcharts'
  Vue.use(VueApexCharts)

  Vue.component('apexchart', VueApexCharts)

  export default {
    name: 'ApexCPieChart',
    props: {
      labels: {
        type: Array,
        default: () => {},
      },
      series: {
        type: Array,
        default: () => [],
      },
      color: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      chartOptions: {
        series: [44, 55, 13],
        chart: {
          width: 300,
          type: 'pie',
        },
        colors: ['#6edb79', '#4555ad', '#d10426'],
        responsive: [{
          breakpoint: 4480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        }],
      },
    }),

    created () {
      this.chartOptions.labels = this.labels
      this.chartOptions.colors = this.colors
    },

    asyncComputed: {},

    methods: {
    },
  }

</script>
<style lang="scss">

</style>
